<template>
    <div class="min-h-screen min-w-screen flex relative lg:fixed surface-ground">
        <perfect-scrollbar class="max-h-screen flex flex-column flex-auto overflow-y-scroll">
            <div class="flex flex-column flex-auto">
                <div class="px-5 pt-5 pb-0">
                    <div class="grid">
                        <!-- Title -->
                        <div class="col-12">
                            <div class="surface-card shadow-2 border-round p-3 mb-3">
                                <div class="flex flex-row justify-content-between">
                                    <p class="flex font-bold capitalize text-left text-2xl">Cookie Policy</p>
                                </div>
                            </div>
                        </div>

                        <!-- Body -->
                        <div class="col-12">
                            <div class="surface-card shadow-2 border-round p-3 mb-3">
                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    What are cookies
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-3">
                                    Cookies are small text files that are used to store small pieces of information.
                                    They are stored on your device when the Website is loaded on your browser.
                                    These cookies help us make the Website function properly, make it more secure, provide better user experience,
                                    and understand how the website performs and to analyse what works and where it needs improvement.
                                </span>

                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    How are cookies used on the Website
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-3">
                                    Our Website uses first-party which are necessary for the Website to function the right way,
                                    and they are in use to provide security.
                                </span>

                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    Third party cookies
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-3">
                                    Not in use.
                                </span>

                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    Other tracking technologies
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-3">
                                    Not in use.
                                </span>

                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    Cookie Types
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-1">
                                    Essential cookies - These are the only cookies used, they are used to provide the security and for authentication purposes.
                                    Outlined below are the cookies used and their full purpose.
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-1">
                                    Statistics cookies - Not in use.
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-1">
                                    Marketing cookies - Not in use.
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-1">
                                    Preference cookies - Preference cookies are used to store your choices on the dashboard.
                                    For example, whether you have dark mode or light mode selected, what sidebar options are collapsed, and more.
                                    No personally identifiable information is stored within these cookies.
                                </span>

                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    Specific cookies in use
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-0">
                                    When you login the essential cookies are loaded. Additional cookies are loaded only after the your permission is granted.
                                </span>
                                <ul class="list-disc pb-3">
                                    <li>
                                        Authorisation - Alive till 24:00 UTC of the day of creation - This cookie is used to provide you with security.
                                        The cookie also holds the your authentication token for additional security.
                                    </li>
                                    <li>
                                        cookieConsent - 30 Days - This cookie remembers your cookie choices per cookie category.
                                    </li>
                                    <li>
                                        preferences - 30 Days - This cookie remembers your preferences. When you login the preferences are loaded.
                                        If you change your preferences they are saved in this cookie and the 30 days lifetime resets.
                                    </li>
                                </ul>

                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    How you can delete and control cookies
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-1">
                                    Since the Website only uses essential cookies you cannot opt out.
                                    If you still want to opt out you must cease using the Website.
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-3">
                                    In addition to this, different browsers provide different methods to block and delete cookies used by websites.
                                    You can change the settings of your browser to block/delete the cookies.
                                    To find out more about how to manage and delete cookies, visit wikipedia.org or allaboutcookies.org.
                                </span>

                                <span class="flex font-bold capitalize text-left text-2xl line-height-3 pb-2">
                                    Policy Amendments
                                </span>
                                <span class="flex text-left line-height-3 text-lg pb-3">
                                    We may update this cookie policy from time to time in order to reflect, for example,
                                    changes to the cookies we use or for other operational, legal or regulatory reasons.
                                    Please therefore re-visit this cookie policy regularly to stay informed about our use of cookies and related technologies.
                                </span>

                                <span class="flex font-bold capitalize text-left text-xl line-height-3 pb-2">
                                    Last Updated: 29/6/23
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <Footer/>
        </perfect-scrollbar>
    </div>
</template>

<script>
import Footer from "@/views/shared/Footer";

export default {
    name: "CookiePolicy",
    components: {
        Footer
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/layout.scss';
</style>
