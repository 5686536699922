<template>
    <div class="px-4 md:px-6 lg:px-8">
        <div class="pb-3 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
            <div>
                <div class="mt-2 line-height-3">&copy; {{ new Date().getFullYear() }} Cyber Theft Watch Ltd. All rights reserved</div>
            </div>
            <div class="mt-2 sm:mt-0">
                <router-link to="/cookies" target="_blank" v-ripple style="text-decoration: none; color: inherit;"
                             class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                    <span class="line-height-3 font-medium">Cookie Policy</span>
                </router-link>
                <a href="https://cybertheftwatch.com/privacy" target="_blank" v-ripple style="text-decoration: none; color: inherit;"
                             class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
                    <span class="line-height-3 font-medium">Privacy Policy</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

</style>
